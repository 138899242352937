import {
  BookOpenIcon,
  CodeBracketIcon,
  ArchiveBoxArrowDownIcon,
  PuzzlePieceIcon,
  BoltIcon,
} from '@heroicons/react/24/outline';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import DynHeightIframe from '../components/util/dynHeightIframe';

export const query = graphql`
  query GovData($jsonId: String!) {
    govComponentsJson(jsonId: { eq: $jsonId }) {
      components {
        type
        individuals
        group
        documentation
      }
      jsonId
      npm
      github
      description
      implementation
    }
    govSystemsJson(jsonId: { eq: $jsonId }) {
      country
    }
  }
`;

const GovDetailsTemplate = ({ data }) => {
  const { components, description, npm, github, implementation } =
    data.govComponentsJson;
  const { country } = data.govSystemsJson;

  return (
    <Layout>
      <SEO
        title={`${country} GOV Design System`}
        description={`Components and details of the ${country} Government Design System`}
      />
      <div>
        <h1 className="mb-3 text-3xl font-semibold text-orange-600">{`${country} Government Design System`}</h1>
        <p className="mb-8 text-lg font-light text-slate-700">{description}</p>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-3">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500 lg:text-lg">
              GitHub
            </dt>
            <dd className="mt-1 flex items-center text-sm text-slate-900 lg:text-lg">
              <CodeBracketIcon className="mr-1 h-5 w-5 text-slate-400" />
              {github ? (
                <a
                  className="underline decoration-slate-400 transition-colors duration-200 ease-in-out hover:text-teal-600"
                  href={`https://github.com/${github}`}
                >
                  {github}
                </a>
              ) : (
                '-'
              )}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500 lg:text-lg">
              NPM
            </dt>
            <dd className="mt-1 flex items-center text-sm text-slate-900 lg:text-lg">
              <ArchiveBoxArrowDownIcon className="mr-1 h-5 w-5 text-slate-400" />
              {npm ? (
                <a
                  className="underline decoration-slate-400 transition-colors duration-200 ease-in-out hover:text-teal-600"
                  href={`https://npmjs.com/package/${npm}`}
                >
                  {npm}
                </a>
              ) : (
                '-'
              )}
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500 lg:text-lg">
              Implementation
            </dt>
            <dd className="mt-1 flex items-center text-sm text-slate-900 lg:text-lg">
              <BoltIcon className="mr-1 h-5 w-5 text-slate-400" />
              {implementation ?? '-'}
            </dd>
          </div>
        </dl>
        <div className="mt-10">
          <h2 className="mb-5 text-2xl font-semibold text-slate-800">
            Components
          </h2>
          {components.map((c) => (
            <div>
              <h3 className="mt-5 mb-3 text-lg font-medium text-teal-600">
                {c.type}
              </h3>
              <DynHeightIframe
                loading="lazy"
                className="max-h-[24rem] w-full"
                title={`${c.type} showcase`}
                src={`https://webdesignsystems.org/sb/iframe.html?args=&id=${c.group}--showcase&viewMode=story`}
              />
              <div className="mt-3">
                {c.documentation && (
                  <a
                    href={c.documentation}
                    className="flex items-center text-slate-900 underline decoration-slate-400 transition-colors duration-200 ease-in-out hover:text-teal-600"
                  >
                    <BookOpenIcon className="mr-1 h-5 w-5 text-slate-400" />
                    Documentation
                  </a>
                )}
                <div className="flex items-center">
                  {c.individuals && (
                    <>
                      <PuzzlePieceIcon className="mr-1 h-5 w-5 text-slate-400" />
                      <span>Storybook Components:</span>
                      {c.individuals.map((i) => (
                        <a
                          href={`/sb/?path=/story/${c.group}--${i}`}
                          className="mx-1 px-1 underline decoration-slate-400 transition-colors duration-200 ease-in-out hover:text-teal-600"
                        >
                          {i}
                        </a>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

GovDetailsTemplate.propTypes = {
  data: PropTypes.shape({
    govComponentsJson: PropTypes.shape({
      components: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          showcase: PropTypes.string.isRequired,
          individuals: PropTypes.arrayOf(PropTypes.string).isRequired,
          group: PropTypes.string.isRequired,
        })
      ).isRequired,
      jsonId: PropTypes.string.isRequired,
      npm: PropTypes.string,
      github: PropTypes.string,
      implementation: PropTypes.string,
      description: PropTypes.string.isRequired,
    }).isRequired,
    govSystemsJson: PropTypes.shape({
      country: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default GovDetailsTemplate;
