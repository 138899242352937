import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const DynHeightIframe = ({ src, title, loading, ...props }) => {
  const iframeEl = useRef(null);

  function setIframeHeight() {
    try {
      // eslint-disable-next-line no-param-reassign
      iframeEl.current.style.height = `${
        iframeEl.current.contentWindow.document.body.scrollHeight + 20
      }px`;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        `Could not set iframe height of "${iframeEl.current.title}": ${e}`
      );
    }
  }

  useEffect(() => {
    if (iframeEl.current) {
      iframeEl.current.onload = setIframeHeight;
      /*
      iframeEl.current.addEventListener('load', setIframeHeight, {
        once: true,
      });
      */
    }
  });

  return (
    <iframe
      ref={iframeEl}
      src={src}
      title={title}
      loading={loading}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

DynHeightIframe.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.string,
};

DynHeightIframe.defaultProps = {
  loading: 'lazy',
};

export default DynHeightIframe;
